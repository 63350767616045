<template>
  <div class="charge-page">
    <v-card flat class="border-color--grey border-1 rounded-10" min-height="500">
      <v-card-title class="primary">
        <span class="title light--text">Credit Charge</span>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          class="px-5 text-none"
          color="secondary"
          @click="$router.go(-1)"
        >
          Back
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row dense class="fill-height" align="stretch">
          <v-col cols="3" order-md="1" order-sm="2">
            <v-sheet
              class="
                fill-height
                fullwidth
                pa-2
                border-color--grey
                rounded-10
                border-1
              "
            >
              <h2
                class="
                  subtitle-1
                  py-1
                  font-weight-bold fs-18
                  text-center
                  mb-2
                  accent
                  rounded-10
                  primary--text
                "
              >
                SMS/MMS Credits
              </h2>
              <h3 class="subtitle-2">
                Current Balance : {{ smsmms_credits.balance | money }}
              </h3>
              <h3 v-if="smsmms_credits.last_recharge_amount" class="subtitle-2">
                Last Recharge Amount :
                {{ smsmms_credits.last_recharge_amount | money }}
              </h3>
              <h3 v-if="smsmms_credits.last_recharge_date" class="subtitle-2">
                Last Recharge Date :
                {{ smsmms_credits.last_recharge_date | format("ll") }}
              </h3>
              <v-divider class="my-5"></v-divider>
              <h2
                class="
                  subtitle-1
                  py-1
                  font-weight-bold fs-18
                  text-center
                  mb-2
                  accent
                  rounded-10
                  primary--text
                "
              >
                Email Credits
              </h2>
              <h3 class="subtitle-2">
                Current Balance : {{ email_credits.balance | money }}
              </h3>
              <h3 v-if="email_credits.last_recharge_amount" class="subtitle-2">
                Last Recharge Amount :
                {{ email_credits.last_recharge_amount | money }}
              </h3>
              <h3 v-if="email_credits.last_recharge_date" class="subtitle-2">
                Last Recharge Date :
                {{ email_credits.last_recharge_date | format("ll") }}
              </h3>
            </v-sheet>
          </v-col>
          <v-col cols="9" order-md="2" order-sm="1">
            <v-form v-model="valid" class="px-md-5 px-sm-0">
              <v-row dense>
                <v-col cols="3">
                  <v-select
                    label="Credit Type"
                    hide-details="auto"
                    :items="credit_types"
                    item-value="value"
                    item-text="name"
                    :rules="requiredRules"
                    v-model="credit_type"
                    outlined
                  ></v-select>
                </v-col>
                <v-spacer></v-spacer>
                <v-btn-toggle
                  v-model="charge_amount"
                  mandatory
                  class="mr-1 mt-1"
                >
                  <v-btn
                    active-class="primary light--text"
                    height="55"
                    value="10"
                  >
                    {{ 10.0 | money }}
                  </v-btn>
                  <v-btn
                    active-class="primary light--text"
                    height="55"
                    value="100"
                  >
                    {{ 100.0 | money }}
                  </v-btn>
                  <v-btn
                    active-class="primary light--text"
                    height="55"
                    value="500"
                  >
                    {{ 500.0 | money }}
                  </v-btn>
                  <v-btn
                    active-class="primary light--text"
                    height="55"
                    class="text-none"
                    value="custom"
                  >
                    Custom
                  </v-btn>
                </v-btn-toggle>
                <v-spacer></v-spacer>
                <v-col cols="3" v-if="is_custom_amount">
                  <v-text-field
                    v-model="custom_charge_amount"
                    class="fullwidth"
                    outlined
                    hide-details="auto"
                    type="number"
                    min="10"
                    max="10000"
                    label="Custom Charge amount:"
                    placeholder="Enter amount"
                    clearable
                    clear-icon="mdi-close-circle-outline"
                    :rules="minMaxRules"
                    :disabled="processing"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-radio-group v-model="payment_method_type" row mandatory>
                    <v-radio label="Use new card" value="new-card"></v-radio>
                    <v-radio
                      :disabled="no_default_card"
                      label="Use default card"
                      value="default-card"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" v-show="payment_method_type === 'new-card'">
                  <label class="primary--text subtitle-2">Payee Details</label>
                  <v-row dense v-if="payment_method_type === 'new-card'">
                    <v-col :cols="mdAndUp ? 6 : 12">
                      <label class="primary--text subtitle-2">First Name</label>
                      <v-text-field
                        v-model="customer.first_name"
                        dense
                        outlined
                        hide-details="auto"
                        :rules="requiredRules"
                      ></v-text-field>
                    </v-col>
                    <v-col :cols="mdAndUp ? 6 : 12">
                      <label class="primary--text subtitle-2">Last Name</label>
                      <v-text-field
                        v-model="customer.last_name"
                        dense
                        outlined
                        hide-details="auto"
                        :rules="requiredRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense v-if="payment_method_type === 'new-card'">
                    <v-col :cols="mdAndUp ? 6 : 12">
                      <label class="primary--text subtitle-2">Email</label>
                      <v-text-field
                        v-model="customer.email"
                        dense
                        outlined
                        hide-details="auto"
                        :rules="emailRules"
                      ></v-text-field>
                    </v-col>
                    <v-col :cols="mdAndUp ? 6 : 12">
                      <label class="primary--text subtitle-2">Address</label>
                      <v-text-field
                        v-model="customer.address"
                        dense
                        outlined
                        hide-details="auto"
                        :rules="requiredRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense v-if="payment_method_type === 'new-card'">
                    <v-col :cols="mdAndUp ? 6 : 12">
                      <label class="primary--text subtitle-2">City</label>
                      <v-text-field
                        v-model="customer.city"
                        dense
                        outlined
                        hide-details="auto"
                        :rules="requiredRules"
                      ></v-text-field>
                    </v-col>
                    <v-col :cols="mdAndUp ? 3 : 12">
                      <label class="primary--text subtitle-2">State</label>
                      <v-text-field
                        v-model="customer.state"
                        dense
                        outlined
                        hide-details="auto"
                        :rules="requiredRules"
                      ></v-text-field>
                    </v-col>
                    <v-col :cols="mdAndUp ? 3 : 12">
                      <label class="primary--text subtitle-2">
                        Postal Code
                      </label>
                      <v-text-field
                        v-model="customer.zip_code"
                        dense
                        outlined
                        hide-details="auto"
                        :rules="requiredRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12">
                      <label
                        for="card-element"
                        class="primary--text subtitle-2"
                      >
                        Card Details
                      </label>
                      <div id="card-element"></div>
                    </v-col>
                  </v-row>
                  <v-btn
                    block
                    :disabled="btn_disabled"
                    :loading="processing"
                    color="primary"
                    @click="processChargeViaNewCard"
                    large
                    class="text-none my-2"
                  >
                    Charge {{ payload.amount | money }}
                  </v-btn>
                  <a
                    class="caption"
                    href="https://stripe.com/"
                    style="text-decoration: none; color: grey"
                    target="_blank"
                  >
                    Powered by <b>Stripe</b>
                  </a>
                </v-col>
                <v-col
                  cols="12"
                  v-show="payment_method_type === 'default-card'"
                  v-if="payment_method"
                >
                  <h5 class="mb-1 subtitle-2 primary--text">Card Details</h5>
                  <label class="primary--text subtitle-2">Email</label>
                  <v-text-field
                    outlined
                    hide-details="auto"
                    class="mb-1"
                    readonly
                    dense
                    :value="payment_method.user.email"
                  ></v-text-field>
                  <label class="primary--text subtitle-2">Card Brand</label>
                  <v-text-field
                    outlined
                    hide-details="auto"
                    class="mb-1"
                    readonly
                    dense
                    :value="payment_method.card.brand"
                  ></v-text-field>
                  <label class="primary--text subtitle-2">Card Last 4</label>
                  <v-text-field
                    outlined
                    hide-details="auto"
                    class="mb-1"
                    dense
                    readonly
                    :value="`**** **** **** ${payment_method.card.last4}`"
                  ></v-text-field>

                  <v-btn
                    block
                    :disabled="btn_disabled"
                    :loading="processing"
                    color="primary"
                    @click="processChargeViaDefaultCard"
                    large
                    class="text-none my-2"
                  >
                    Charge {{ payload.amount | money }}
                  </v-btn>
                  <a
                    class="caption"
                    href="https://stripe.com/"
                    style="text-decoration: none; color: grey"
                    target="_blank"
                  >
                    Powered by <b>Stripe</b>
                  </a>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { loadStripe } from "@stripe/stripe-js";
import { settings } from "@/variables";
import request from "@/services/axios_instance";

export default {
  name: "ChargeCredit",
  data: () => ({
    valid: false,
    processing: false,
    is_custom_amount: false,
    charge_amount: 10.0,
    custom_charge_amount: 10.0,
    options: {
      locale: "en-US",
      prefix: "$",
      suffix: "",
      length: 11,
      precision: 2,
    },
    credit_types: [
      { value: "email", name: "Email Credits" },
      { value: "smsmms", name: "SMS/MMS Credits" },
    ],
    credit_type: "smsmms",
    payment_method_type: "new-card",
    payment_method_id: null,
    stripe: {},
    cardElement: {},
    customer: {
      first_name: null,
      last_name: null,
      email: null,
      address: null,
      city: null,
      state: null,
      zip_code: null,
    },
  }),
  created() {
    this.getSmsMmsCredits();
    this.getEmailCredits();
    this.getCurrentSubscriptions();
    if (this.$route.params.type) {
      this.credit_type = this.$route.params.type;
    }
  },
  async mounted() {
    this.stripe = await loadStripe(settings.stripe.pub_key);
    const element = this.stripe.elements();
    this.cardElement = element.create("card", {
      classes: {
        base: "py-5",
      },
    });
    this.cardElement.mount("#card-element");
    this.mapUser();
  },
  watch: {
    charge_amount(val) {
      this.is_custom_amount = val === "custom";
    },
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("subscription", [
      "smsmms_credits",
      "email_credits",
      "payment_method",
    ]),
    btn_disabled() {
      if (this.processing || !this.valid) return true;
      if (this.is_custom_amount)
        return !(
          this.custom_charge_amount >= 10 && this.custom_charge_amount <= 10000
        );
      return !(this.charge_amount >= 10 && this.charge_amount <= 10000);
    },
    payload() {
      return {
        credit_for: this.credit_type,
        amount: this.is_custom_amount
          ? this.custom_charge_amount
          : this.charge_amount,
        payment_method_type: this.payment_method_type, //default-card or new-card
        payment_method_id: this.payment_method ? this.payment_method.id : null,
      };
    },
    no_default_card() {
      return !this.payment_method;
    },
  },
  methods: {
    ...mapActions("subscription", [
      "getSmsMmsCredits",
      "getEmailCredits",
      "getCurrentSubscriptions",
    ]),
    ...mapMutations("subscription", ["setSmsMmsCredits", "setEmailCredits"]),
    mapUser() {
      this.customer = {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        email: this.user.email,
        address: this.user.props.location || "",
        city: this.user.props.city || "",
        state: this.user.props.state || "",
        zip_code: this.user.props.zip_code || "",
      };
    },
    async processChargeViaNewCard() {
      this.processing = true;
      const { paymentMethod, error } = await this.stripe.createPaymentMethod(
        "card",
        this.cardElement,
        {
          billing_details: {
            name: this.customer.first_name + " " + this.customer.last_name,
            email: this.customer.email,
            address: {
              line1: this.customer.address,
              city: this.customer.city,
              state: this.customer.state,
              postal_code: this.customer.zip_code,
            },
          },
        }
      );
      if (error) {
        this.processing = false;
        this.appSnackbar( "error", "error");
      } else {
        this.processing = true;
        request
          .post(
            `api/credits/${this.credit_type}/new-card`,
            Object.assign({}, this.payload, {
              customer: this.customer,
              payment_method_id: paymentMethod.id,
            })
          )
          .then(({ data }) => {
            if (this.credit_type === "smsmms") this.setSmsMmsCredits(data);
            else if (this.credit_type === "email") this.setEmailCredits(data);
            this.processing = false;
            this.appSnackbar( "Charge success");
            // this.this.$router.push({name: 'subscriptions_summary'})
          })
          .catch((error) => {
            this.processing = false;
            this.appSnackbar( error, "error");
          });
      }
    },
    processChargeViaDefaultCard() {
      this.processing = true;
      request
        .post(`api/credits/${this.credit_type}/default-card`, this.payload)
        .then(({ data }) => {
          if (this.credit_type === "smsmms") this.setSmsMmsCredits(data);
          else if (this.credit_type === "email") this.setEmailCredits(data);
          this.processing = false;
          this.appSnackbar( "Charge success");
          // this.this.$router.push({name: 'subscriptions_summary'})
        })
        .catch((error) => {
          this.processing = false;
          this.appSnackbar( error, "error");
        });
    },
  },
};
</script>

<style scoped lang="scss">
 
</style>